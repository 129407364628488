/*
########   ##     ##  ##    ##    ######    ########   ####   #######   ##    ##    ######    
##         ##     ##  ###   ##   ##    ##      ##       ##   ##     ##  ###   ##   ##    ##   
##         ##     ##  ####  ##   ##            ##       ##   ##     ##  ####  ##   ##         
######     ##     ##  ## ## ##   ##            ##       ##   ##     ##  ## ## ##    ######    
##         ##     ##  ##  ####   ##            ##       ##   ##     ##  ##  ####         ##   
##         ##     ##  ##   ###   ##    ##      ##       ##   ##     ##  ##   ###   ##    ##   
##          #######   ##    ##    ######       ##      ####   #######   ##    ##    ######    
*/


var navigation = responsiveNav(".nav-main", {
	animate: true,                    // Boolean: Use CSS3 transitions, true or false
	transition: 284,                  // Integer: Speed of the transition, in milliseconds
	label: "",                    // String: Label for the navigation toggle
	insert: "before",                  // String: Insert the toggle before or after the navigation
	customToggle: "#nav-toggle",                 // Selector: Specify the ID of a custom toggle
	closeOnNavClick: true,           // Boolean: Close the navigation when one of the links are clicked
	openPos: "relative",              // String: Position of the opened nav, relative or static
	navClass: "nav-main",         // String: Default CSS class. If changed, you need to edit the CSS too!
	navActiveClass: "js-nav-active",  // String: Class that is added to <html> element when nav is active
	jsClass: "js",                    // String: 'JS enabled' class which is added to <html> element
});

/* Fonction pour initialiser les players audio */
function js_audioPlayer(file, title, location) {
	jQuery("#jp_player_" + location).jPlayer( {
	    ready: function () {
			jQuery(this).jPlayer("setMedia", {
				title: title,
				mp3: file
			});
	    },
	    play: function() { // To avoid multiple jPlayers playing together.
			$(this).jPlayer("pauseOthers");
		},
		cssSelectorAncestor: "#jp_container_" + location,
		swfPath: "/include/js",
		supplied: "mp3",
		useStateClassSkin: true,
		smoothPlayBar: true,
		keyEnabled: false,
		remainingDuration: false,
		toggleDuration: false,
		globalVolume: true
	});
	
	return;
}

/*
########    #######   ##     ##       ########   ########      ###     ########   ##    ##   
##     ##  ##     ##  ###   ###       ##     ##  ##           ## ##    ##     ##   ##  ##    
##     ##  ##     ##  #### ####       ##     ##  ##          ##   ##   ##     ##    ####     
##     ##  ##     ##  ## ### ##       ########   ######     ##     ##  ##     ##     ##      
##     ##  ##     ##  ##     ##       ##   ##    ##         #########  ##     ##     ##      
##     ##  ##     ##  ##     ##       ##    ##   ##         ##     ##  ##     ##     ##      
########    #######   ##     ##       ##     ##  ########   ##     ##  ########      ##      
*/

/**
 * Fastclick
 */
window.addEventListener('load', function() {
	FastClick.attach(document.body);
}, false);

/**
 * jQuery stuff
 */
$(document).ready(function() {

	/**
	 * Test si les Media Queries sont supportées
	 * Ajoute la classe 'mq' au tag HTML si c'est le cas
	 */
	if ( Modernizr.mq('only all') ) {
		jQuery('html').addClass('mq');
	} else {
		jQuery('html').addClass('no-mq');
	}

	/**
	 * Browser detect : masquage de la bannière
	 */
	$('.browserdetect__close').click(function(e) {
		e.preventDefault();
		$('.browserdetect').addClass('browserdetect--hidden');
	});

	/* Initialisation des players audio */
	$(".jp-jplayer").each(function(){
		js_audioPlayer( $(this).attr('data-file'), $(this).attr('data-title') , $(this).attr('data-index'));
	});

	/* Lecture des videos */
	$("#video-gallery").lightGallery({
		thumbnail: false,
		videoAutoplay: true,
		videoMaxWidth: "1200px"
	});

	/* Lecture des videos */
	$(".gallery").lightGallery({
		thumbnail: false,
	});

	// action sur les formulaires
	$("form").validationEngine({
		scroll: false,
		binded: false,
		promptPosition: 'centerRight:-136,-3',
		addFailureCssClassToField: 'inputError',
		addSuccessCssClassToField: 'inputTrue',
		autoPositionUpdate: true,
		maxErrorsPerField: 1
	});

	$("#fContact").submit(function(){		
		if($("#fContact").validationEngine('validate')){
			var data = $(this).serialize() + '&act=envoi';
			var div  = $(".formReturn").empty().html('<div class="callback"><span>envoi en cours...</span></div>');

			$.ajax({
				type    : "POST",
				data    : data,
				url     : "/contact/",
				success : function(retour) {
					div.empty().append(retour);
				}
			});
		}
		return false;
	});


	$('.news-item').matchHeight();
});